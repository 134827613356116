<template>
  <div class="full-screen" style="z-index: -1;">
    <iframe v-for="(iframeUrl, index) in urls" :key="index" :src="iframeSources[index]" width="100%" height="100%" :style="{ display: showIframe(index) ? 'block' : 'none' }" frameborder="0" allowfullscreen ref="iframes" @load="onIframeLoad(index)" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "IframeSwitcher",
  computed: mapGetters(["urls", "interval", "autoRefresh"]),
  data() {
    return {
      currentIndex: 0,
      prevIndex: 0,
      width: "100%",
      height: "100%",
      switchTimer: null,
      iframeSources: [],
      loadedIframes: [],
    };
  },
  mounted() {
    this.initializeIframeSources();
    this.loadIframe(0);
    setTimeout(this.changeIframe, this.interval);
  },
  methods: {
    initializeIframeSources() {
      this.iframeSources = [];
      this.loadedIframes = [];
      this.urls.forEach(() => {
        this.iframeSources.push("placeholder");
        this.loadedIframes.push(false);
      });
    },
    changeIframe() {
      this.switchTimer && clearTimeout(this.switchTimer);
      if (this.urls.length === 0) {
        return;
      }
      this.prevIndex = this.currentIndex;
      this.currentIndex = (this.currentIndex + 1) % this.urls.length;
      this.loadIframe(this.currentIndex);
      this.reloadIframeIfAutoRefresh(this.prevIndex);
      console.log(`Changing iframe to index ${this.currentIndex} of ${this.urls.length}`);
      this.switchTimer = setTimeout(this.changeIframe, this.interval);
    },
    showIframe(index) {
      return index === this.currentIndex;
    },
    onIframeLoad(index) {
      // Check if it's just a placeholder
      if (this.iframeSources[index] === "placeholder") {
        console.log(`Iframe ${index} loaded with placeholder!`);
        if (this.autoRefresh[index] && this.prevIndex === index) {
          console.log(`Iframe ${index} is set to auto refresh!`);
          this.iframeSources[index] = this.urls[index];
          this.loadedIframes[index] = false;
        }
        return;
      }
      // Set as loaded
      this.loadedIframes[index] = true;
      console.log(`Iframe ${index} loaded with URL ${this.iframeSources[index]}!`);
    },
    loadIframe(index) {
      if (this.loadedIframes[index]) {
        console.log(`Iframe ${index} already loaded!`);
        return;
      }
      this.iframeSources[index] = this.urls[index];
    },
    reloadIframeIfAutoRefresh(index) {
      if (!this.autoRefresh[index]) {
        console.log(`Iframe ${index} is not set to auto refresh!`);
        return;
      }
      console.log(`Reloading iframe ${index}!`);
      this.iframeSources[index] = "placeholder";
      this.loadedIframes[index] = false;
    }
  },
  watch: {
    urls() {
      this.currentIndex = 0;
      this.initializeIframeSources();
      this.loadIframe(0);
    },
  },
};
</script>

<style scoped>
.full-screen {
  margin: 0px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
</style>
