<template>
    <div></div>
</template>
<script>
import {
    mapGetters,
    mapActions
} from 'vuex'
import axios from 'axios';
import config from '../config';

export default {
    name: 'BackendController',
    computed: mapGetters(['urls', 'interval']),
    data() {
        return {
            updateTimer: null,
            updateInterval: 60000,
        }
    },
    methods: {
        updateFlow() {
            // Clear timeout to prevent multiple calls
            this.updateTimer && clearTimeout(this.updateTimer);
            // Update flow from backend
            const query = this.$route.query;
            if (query.flow) {
                const flow = query.flow;
                // Query data from backend and update flow
                axios.get(
                    `${config.api}/api/tabflows/?name=${flow}`
                ).then(response => {
                    const data = response.data;
                    if (data.length > 0) {
                        const flow = data[0];
                        // Extract interval
                        const interval = flow.interval_seconds * 1000;
                        // Extract urls
                        const urls = flow.tabs.map(tab => tab.url);
                        // Extract auto refresh settings
                        const autoRefresh = flow.tabs.map(tab => tab.auto_refresh)
                        // Update interval if changed
                        if (interval !== this.interval) {
                            console.log(`Updating interval to ${interval}`);
                            this.setInterval(interval);
                        } else {
                            console.log(`Interval is already ${interval}, not changing`);
                        }
                        // Update urls if changed
                        if (urls.length !== this.urls.length || urls.some((url, index) => url !== this.urls[index])) {
                            console.log(`Updating urls to ${urls}`);
                            this.setUrls(urls);
                            console.log(`Updating auto refresh to ${autoRefresh}`);
                            this.setAutoRefresh(autoRefresh);
                        } else {
                            console.log(`Urls are already ${urls}, not changing neither URLs nor auto refresh settings`);
                        }
                    }
                });
                }
            // Set timeout to call this function again
            this.updateTimer = setTimeout(this.updateFlow, this.updateInterval);
        },
        ...mapActions(['addUrl', 'removeUrl', 'setInterval', 'setUrls', 'setAutoRefresh'])
    },
    mounted() {
        this.updateFlow();
    }
}
</script>
