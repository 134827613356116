import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const state = {
  urls: [],
  autoRefresh: [],
  interval: 30000
};

const actions = {
  addUrl: function (store, url) {
    store.commit("addUrl", url);
  },
  removeUrl: function (store, index) {
    store.commit("removeUrl", index);
  },
  setUrls: function (store, urls) {
    store.commit("setUrls", urls);
  },
  setInterval: function (store, interval) {
    store.commit("setInterval", interval);
  },
  setAutoRefresh: function (store, autoRefresh) {
    store.commit("setAutoRefresh", autoRefresh);
  }
};

const mutations = {
  setUrls: function (state, urls) {
    // Set the urls
    state.urls = urls;
  },
  setInterval: function (state, interval) {
    // Set the interval
    state.interval = interval;
  },
  setAutoRefresh: function (state, autoRefresh) {
    // Set the autoRefresh
    state.autoRefresh = autoRefresh;
  }
};

const getters = {
  urls: function (state) {
    return state.urls;
  },
  interval: function (state) {
    return state.interval;
  },
  autoRefresh: function (state) {
    return state.autoRefresh;
  }
};

export default new Vuex.Store({
  state,
  actions,
  getters,
  mutations
});
